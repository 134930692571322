<template>
  <div class="p-5 bg-light">
    <div v-if="articleLoading" class="p-5">
      <Loading />
    </div>
    <div v-if="!articleLoading && article" class="col-xxl-8 bg-white p-5 rounded-lg shadow-sm border mx-auto">
      <div class="col-12 p-0 row m-0 justify-content-center flex-column align-items-center">
        <div class="col-12 text-left p-0 m-0 mb-4 align-items-center">
          <div class="row m-0">
            <BushelBtn variant="success" style="font-size: 12px" class="btn-sm mr-3 mb-4" @click="onBackClick">
              <i class="fas fa-angle-left" /> Back
            </BushelBtn>
            <BushelBtn
              v-if="article && article.next_page_link"
              variant="success"
              style="font-size: 12px"
              class="btn-sm ml-auto mb-4"
              @click="$router.push({ path: '/kb/' + categorySlug })"
            >
              Next <i class="fas fa-angle-right" />
            </BushelBtn>
          </div>
          <div class="col-12 p-0">
            <div class="d-flex justify-content-start align-items-center text-sm">
              <p v-if="article.category" class="font-weight-bold">
                Category:
                <span v-if="article.category" class="font-weight-normal">
                  {{ article.category.map((obj) => obj.name).join(", ") }}
                </span>
              </p>
              <p v-if="article.category" class="ml-auto font-weight-bold">{{ dateShortLocalized(article.created_at) }}</p>
            </div>

            <h2 class="font-weight-bold m-0 mb-1 text-capitalize text-dark text-wrap">
              {{ article.title }}
            </h2>
            <p style="line-height: 2" class="font-weight-normal m-0 mb-1 text-capitalize text-dark text-wrap">
              {{ article.description }}
            </p>
          </div>
        </div>
      </div>
      <video v-if="article && article.video_url" class="w-100 kb-video" controls :src="article.video_url" />
      <div v-if="!articleLoading" v-html-safe="article.content" class="kb-article text-wrap" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as api from "@/services/api";
import { dateShortLocalized } from "@/services/filters";

export default {
  name: "SupportArticle",
  props: {
    categorySlug: { type: String },
    articleSlug: { type: String, required: true },
  },
  data() {
    return {
      article: null,
      articleLoading: false,
      storageIndex: "apex-kb-recent",
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/currentCompany",
    }),
  },
  mounted() {
    this.fetchArticleBySlug();
  },
  methods: {
    dateShortLocalized,
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    onBackClick() {
      const params = new URLSearchParams(window.location.search);
      const url = `/kb?`;

      this.$router.push({
        path: url,
        query: { page: params.get("page"), search: params.get("search"), category: params.get("category") },
      });
    },
    fetchArticleBySlug() {
      this.articleLoading = true;
      api.get(`/support/article/${this.articleSlug}`).then(
        (response) => {
          if (response.data) {
            this.article = response.data;
            const path = `kb/${this.categorySlug}/${this.article.slug}`;
            const stored = this.getArticleStorage();
            if (!stored) {
              window.sessionStorage.setItem(
                this.storageIndex,
                JSON.stringify([
                  {
                    category: this.categorySlug,
                    path,
                    title: this.article.title,
                  },
                ]),
              );
            } else if (stored && !this.isArticleInStorage(stored, path)) {
              stored.push({
                category: this.categorySlug,
                path,
                title: this.article.title,
              });
              window.sessionStorage.setItem(this.storageIndex, JSON.stringify(stored));
            }
          }
          this.articleLoading = false;
        },
        (error) => {
          this.articleLoading = false;
          this.setSnackbar({
            variant: "danger",
            title: "Error",
            message: "There was an error loading categories",
          });
        },
      );
    },
    stripTags(content) {
      return content.replace(/(<([^>]+)>)/gi, "");
    },
    getArticleStorage() {
      const stored = window.sessionStorage.getItem(this.storageIndex);
      if (stored) {
        return JSON.parse(stored);
      }
      return false;
    },
    isArticleInStorage(stored, path) {
      if (stored && stored.some((item) => item.path == path)) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
:deep(ol) {
  padding: 0 !important;
}
:deep(ul) {
  padding: 0 !important;
}
strong,
b {
  font-weight: 700;
}
:deep(.kb-article p) {
  font-size: 0.9rem !important;
  line-height: 1.75rem;
}
:deep(.kb-article img) {
  padding: 1rem;
  background: var(--light);
  border: #eee 1px solid !important;
  border-radius: 0.25rem;
  max-width: 100% !important;
}
.kb-video {
  padding: 1rem;
  background: var(--light);
  border: #eee 1px solid !important;
  border-radius: 0.25rem;
  outline: none;
}
.card-header-color {
  background: #e9ecef !important;
}
</style>
