<template>
  <div v-if="signatures && signatures.length >= 1">
    <h2 class="doc-type-label mt-4">E-Signatures</h2>
    <div class="row mx-0">
      <div v-for="sig in signatures" :key="sig.id" class="col-12 col-lg-5 mx-auto my-2 signature-card">
        <div class="signature-info">
          <p class="doc-type-label mr-1 mb-1">Name: {{ sig.signer_name }}</p>
          <p class="doc-type-label mr-1 mb-1">Company: {{ sig.signer_company }}</p>
          <p class="doc-type-label mb-1">Date: {{ datetimeLocalized(sig.created_at) }}</p>
        </div>
        <img :src="sig.image" :alt="doc.name" :style="{ maxWidth: '100%' }" />
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/api";
import { datetimeLocalized } from "@/services/filters";

export default {
  components: {},
  props: {
    doc: { type: Object, required: true },
  },

  data() {
    return {
      signatures: [],
    };
  },
  computed: {},
  mounted() {
    this.loadSignatures();
  },
  methods: {
    datetimeLocalized,
    loadSignatures() {
      if (this.doc.uuid) {
        api.get(`docs/signatures/${this.doc.uuid}`).then(
          (response) => {
            if (response.data) {
              const sigs = response.data.map((sig) => ({
                ...sig,
                image: `data: ${sig.mime_type};base64, ${sig.image}`,
              }));
              this.signatures = sigs;
            } else {
              //
            }
          },
          (error) => {
            //
          },
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@sass/_variables.scss";

.doc-type-label {
  background-color: #eceef1;
  border: none;
  color: $label-color;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 0px 5px;
  margin: 2px 0px;
  border-radius: 4px;
  word-break: break-word;
}

.signature-card {
  border: 1px dashed #4f6281;
  padding: 10px;
}

.signature-info {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
</style>
