<template>
  <div>
    <div v-if="authorized" class="example-avatar p-1 m-3" :class="disabled || loading ? 'disabled' : ''">
      <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
        <h3>Drop files to upload</h3>
      </div>
      <div v-show="!addingLabel" class="avatar-upload">
        <div class="text-left">
          <label :for="inputId" class="w-100 d-flex" style="height: 100px">
            <div class="m-auto">
              <h4 class="pt-2 pb-0 mb-1 d-block">Click or Drop File</h4>
              <i class="fas d-block" :class="loading ? 'fa-circle-notch fa-spin' : 'fa-cloud-upload-alt'" />
            </div>
          </label>
        </div>
        <div class="text-center p-2">
          <file-upload
            ref="upload"
            v-model="files"
            :disabled="disabled || loading"
            extensions="gif,jpg,jpeg,png"
            :name="inputId"
            class="btn btn-primary"
            :drop="!addingLabel"
            :input-id="inputId"
            @input-filter="inputFilter"
            @input-file="inputFile"
          >
            Click or Drop File
          </file-upload>
        </div>
      </div>

      <div v-show="files.length && addingLabel" class="avatar-edit">
        <div v-if="files.length" class="avatar-edit-image">
          <div class="row text-center">
            <div class="col-12 col-md-6 align-self-center">
              <!-- <div style="max-width: 150px; width: 150px; max-height: 100px; margin: auto;"> -->
              <small>{{ files[0].name }}</small>
              <!-- </div> -->
            </div>
            <div class="col-12 col-md-6 pl-md-0 mt-2 mt-md-0">
              <div>
                <BushelSelect
                  v-if="labelList && labelList.length >= 1"
                  :label-active="labelRequired ? 'Label (required)' : 'Label'"
                  thin
                  :options="labelList"
                  :taggable="taggable"
                  :multiple="multiple"
                  :error="submitted && labelRequired"
                  :value="docLabel"
                  @tag="tagAdded"
                  @select="tagSelected"
                  @remove="tagRemoved"
                />
                <BushelTextInput
                  v-else
                  v-model="docLabel"
                  :error="submitted && labelRequired"
                  type="text"
                  :label-active="labelRequired ? 'Label (required)' : 'Label'"
                  thin
                />
              </div>
              <div v-if="showSigOptions" class="text-left">
                <div class="custom-checkbox checkbox-success checkbox-grey-200">
                  <input id="checkbox-requires-signature" v-model="requires_signature" type="checkbox" />
                  <label for="checkbox-requires-signature">
                    <span />
                    Signature Required
                  </label>
                </div>
                <div class="custom-checkbox checkbox-success checkbox-grey-200">
                  <input id="checbox-e-signable" v-model="e_signable" type="checkbox" />
                  <label for="checbox-e-signable">
                    <span />
                    Electronic Signature Allowed
                  </label>
                </div>
              </div>
              <div class="text-center m-auto">
                <button type="button" class="btn btn-secondary m-1" @click.prevent="$refs.upload.clear">Cancel</button>
                <button type="submit" class="btn btn-success m-1" @click.prevent="submitDoc">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table v-if="documents && documents.length >= 1" class="table table-valign-middle">
        <thead>
          <tr>
            <th class="text-center align-middle">Doc</th>
            <th class="text-center align-middle">Uploaded</th>
            <th v-if="authorized && showDelete" class="text-center align-middle">Delete</th>
            <th class="text-center align-middle">
              {{ potentiallySignable && relation ? "View/Sign" : "View" }}
            </th>
            <th class="text-center align-middle">Download</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="doc in documents" :key="doc.id">
            <td class="text-center align-middle nowrap">
              <div class="overflow-hidden">
                <p class="text-nowrap mb-2">
                  {{ doc.label }}
                  <span v-if="!doc.label" class="text-small text-light text-nowrap">
                    {{ doc.name }}
                  </span>
                </p>
                <div class="clearfix mb--half" />
                <span v-if="doc.label" class="text-small text-light text-nowrap">
                  {{ doc.name }}
                </span>
              </div>
            </td>
            <td class="text-center align-middle nowrap">
              {{ calendartimeLocalized(doc.created_at ? doc.created_at : new Date()) }}
            </td>
            <td v-if="authorized && showDelete" class="text-center align-middle nowrap">
              <BushelBtn variant="danger" :disabled="disabled || loading" @click="startDeleteDoc(doc)">
                <i class="text-white fa fa-times" />
              </BushelBtn>
            </td>
            <td class="text-center align-middle nowrap">
              <div v-if="canDisplayImage(doc.ext)">
                <BushelBtn :disabled="disabled || loading" variant="success" @click="openDocViewerModal(doc)">
                  <i class="text-white fa fa-file" />
                </BushelBtn>
              </div>
              <div />
            </td>
            <td class="text-center align-middle nowrap">
              <a v-if="!disabled && !loading" :href="downloadLink(doc)" :download="doc.name" target="_blank">
                <BushelBtn :disabled="disabled || loading" variant="success">
                  <i class="text-white fa fa-download" />
                </BushelBtn>
              </a>
              <BushelBtn v-if="disabled || loading" :disabled="disabled || loading" variant="success">
                <i class="text-white fa fa-download" />
              </BushelBtn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <DocumentViewer
      v-if="visibleDoc"
      :download-path="downloadPath"
      :signable="visibleDoc.e_signable && authorized"
      :show="isShowingDocViewer"
      :doc="visibleDoc"
      @document-modal-closed="docViewerClosed"
      @signature-created="(val) => $emit('signatureCreated', val)"
    />
    <DeleteConfirmationModal :show="isShowingDeleteConfirmModal" @hide="hideDeleteConfirmationModal" @confirm="deleteDoc" />
  </div>
</template>

<script>
import FileUpload from "vue-upload-component";
import DocumentViewer from "./DocumentViewer.vue";
import { calendartimeLocalized } from "@/services/filters";

export default {
  components: { FileUpload, DocumentViewer },
  props: {
    downloadPath: { type: String, default: "docs" },
    documents: { type: Array, required: true },
    potentiallySignable: { type: Boolean, default: false },
    showSigOptions: { type: Boolean, default: false },
    docableType: { type: String },
    relation: { type: Number },
    labelList: { type: Array },
    labelRequired: { type: Boolean, default: false },
    taggable: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    inputId: { type: String, default: "document_list" },
    authorized: { type: Boolean, default: true },
    showDelete: { type: Boolean, default: true },
    checkDownloadLinks: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  emits: ["createDocument", "deleteDocument", "unsavedDocuments", "signatureCreated"],
  data() {
    return {
      files: [],
      isShowingDeleteConfirmModal: false,
      addingLabel: false,
      docLabel: this.taggable && this.labelList && this.labelList.length >= 1 ? [] : "",
      e_signable: false,
      docAwaitingDeletion: null,
      requires_signature: false,
      isShowingDocViewer: false,
      visibleDoc: null,
      submitted: false,
    };
  },
  computed: {},
  watch: {
    files(val, oldVal) {
      if (val.length > 0) {
        this.$emit("unsavedDocuments", true);
      } else {
        this.$emit("unsavedDocuments", false);
      }
    },
  },
  mounted() {},
  methods: {
    calendartimeLocalized,
    hideDeleteConfirmationModal() {
      this.isShowingDeleteConfirmModal = false;
      this.docAwaitingDeletion = null;
    },
    startDeleteDoc(payload) {
      this.docAwaitingDeletion = payload;
      this.isShowingDeleteConfirmModal = true;
    },
    deleteDoc() {
      this.isShowingDeleteConfirmModal = false;
      this.$emit("deleteDocument", this.docAwaitingDeletion);
      this.docAwaitingDeletion = null;
    },
    tagAdded(tag) {
      if (this.multiple) {
        this.docLabel.push(tag);
      } else {
        this.docLabel = tag;
      }
    },
    tagSelected(tag) {
      if (this.multiple) {
        this.docLabel.push(tag);
      } else {
        this.docLabel = tag;
      }
    },
    tagRemoved(tag) {
      if (this.multiple) {
        this.docLabel = this.docLabel.filter((t) => t != tag);
      } else {
        this.docLabel = "";
      }
    },
    openDocViewerModal(doc) {
      this.isShowingDocViewer = true;
      this.visibleDoc = doc;
    },
    docViewerClosed() {
      this.isShowingDocViewer = false;
      this.visibleDoc = null;
    },
    canDisplayImage(ext) {
      switch (ext) {
        case ".pdf":
        case ".jpg":
        case ".jpeg":
        case ".jfif":
        case ".pjpeg":
        case ".pjp":
        case ".png":
        case ".svg":
        case ".bmp":
        case ".ico":
        case ".gif":
          return true;
        default:
          return false;
      }
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.addingLabel = true;
        });
      }
      if (!newFile && oldFile) {
        this.addingLabel = false;
      }
    },

    inputFilter(newFile, oldFile, prevent) {
      // if (newFile && !oldFile) {
      //   if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
      //     return prevent();
      //   }
      // }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    createFile(file, cb) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        // there was an error creating doc
      };
    },
    submitDoc() {
      this.submitted = true;
      const file = this.files[0];
      if (!this.labelRequired || this.docLabel.length) {
        this.addingLabel = false;
        this.createFile(file.file, (result) => {
          const upload = {
            file: result,
            name: file.name.replace(/\s+/g, "-"),
            ext: file.name.substring(file.name.lastIndexOf("."), file.name.length),
            mime_type: file.type,
            label: this.docLabel,
            e_signable: this.e_signable,
            requires_signature: this.requires_signature,
            size: file.size,
          };
          this.$emit("createDocument", upload);
          this.label = null;
          this.e_signable = false;
          this.submitted = false;
          this.requires_signature = false;
          this.files = [];
          this.addingLabel = false;
          this.docLabel = "";
        });
      }
    },
    downloadLink(doc) {
      if (this.checkDownloadLinks) {
        return doc.uuid ? `/b-api/${this.downloadPath}/download/${doc.uuid}` : doc.file;
      }
      return this.relation ? `/b-api/${this.downloadPath}/download/${doc.uuid}` : doc.file;
    },
  },
};
</script>

<style scoped>
:deep(label) {
  /* border: 2px dashed grey; */
  text-align: center;
}
:deep(.avatar-upload div:nth-child(2)) {
  display: none;
}
.example-avatar {
  border: 2px dashed grey;
  border-radius: 5px;
}
.example-avatar.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
:deep(.cropper-point.point-se) {
  bottom: -3px;
  cursor: nwse-resize;
  height: 5px;
  opacity: 1;
  right: -3px;
  width: 5px;
}
.avatar-upload-photo {
  max-height: 100px;
}
.example-avatar .avatar-edit-image {
  max-width: 100%;
}
.example-avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.example-avatar .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.info-table {
  margin-top: -5px;
}
.info-table tr {
  border-top: 1px solid #dee2e694;
}
.info-table tr:hover {
  background: initial !important;
}
.info-table td {
  padding: 0.35rem 0.25rem !important;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #374767;
  border-top: none;
}
.info-table tr:first-child {
  border-top: none;
}
.tr-labels {
  border-top: 1px solid #f5f6f7;
}
.info-table .tr-labels td {
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 0px;
  color: #777 !important;
}
.info-stamp {
  font-family: "Times New Roman", serif;
  color: #c0cadd;
  font-size: 12px;
}
</style>
