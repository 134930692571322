import store from "@/store";
import { userCan } from "@/services/helpers";

// Semi-public routes
// import externalServices from '@/pages/externalServices/ServicesMarkeplace.vue';

// Semi-public routes
// import externalServices from '@/pages/externalServices/ServicesMarkeplace.vue';
const ServicesMarkeplace = () => import("@/pages/externalServices/ServicesMarkeplace.vue");

// ***************************** Async Routes ****************************
// dev routes
const Kiosk = () => import("@/pages/kiosk/Kiosk.vue");

// general routes
const Main = () => import("@/layouts/Main.vue");
const SlashPage = () => import("@/layouts/SlashPage.vue");
const NotFound = () => import("@/pages/404.vue");
// const ComingSoon = () => import( '@/components/common/ComingSoon.vue');
const BuyerOnboardCompanySelect = () => import("@/pages/onboardCompanySelect/BuyerOnboardCompanySelect.vue");
const BrandOnboardCompanySelect = () => import("@/pages/onboardCompanySelect/BrandOnboardCompanySelect.vue");
const Storefront = () => import("@/pages/storefront/Storefront.vue");
// const CompanyStorefront = () => import( '@/pages/storefront/StoreFrontPreview');
const TermsOfService = () => import("@/pages/termsOfService/TermsOfService.vue");
const ChangeLog = () => import("@/pages/changeLog/ChangeLog.vue");

// Reporting
const ReportingLanding = () => import("@/pages/reporting/ReportingLanding.vue");
const AdvancedReportingSellerOrders = () => import("@/pages/reporting/advanced/AdvancedReportingSellerOrders.vue");
const AdvancedReportingCurrentInventory = () => import("@/pages/reporting/advanced/AdvancedReportingCurrentInventory.vue");
const AdvancedReportingAllProductSales = () => import("@/pages/reporting/advanced/AdvancedReportingAllProductSales.vue");
const AdvancedReportingBuyerOrders = () => import("@/pages/reporting/advanced/AdvancedReportingBuyerOrders.vue");
const BasicReportingSellerOrders = () => import("@/pages/reporting/basic/BasicReportingSellerOrders.vue");
const BasicReportingBuyerOrders = () => import("@/pages/reporting/basic/BasicReportingBuyerOrders.vue");
const BasicReportingProductSales = () => import("@/pages/reporting/basic/BasicReportingProductSales.vue");
const BasicReportingInventoryOverview = () => import("@/pages/reporting/basic/BasicReportingInventoryOverview.vue");
const BasicReportingBrandCompanyInventory = () => import("@/pages/reporting/basic/BasicReportingBrandCompanyInventory.vue");
const BasicReportingBrandCompanyOrders = () => import("@/pages/reporting/basic/BasicReportingBrandCompanyOrders.vue");
const PerformanceReportingProduct = () => import("@/pages/reporting/performance/PerformanceReportingProduct.vue");
const PerformanceReportingBuyer = () => import("@/pages/reporting/performance/PerformanceReportingBuyer.vue");
const BasicSellerSamplesReport = () => import("@/pages/reporting/basic/BasicSellerSamplesReport.vue");
const BasicSellerBrandsReport = () => import("@/pages/reporting/basic/BasicSellerBrandsReport.vue");
const PerformanceReportingRepSales = () => import("@/pages/reporting/performance/PerformanceReportingRepSales.vue");
const BasicReportingBuyerRecentOrders = () => import("@/pages/reporting/basic/BasicReportingBuyerRecentOrders.vue");
// Auth Routes
const ResetPassword = () => import("@/pages/auth/ResetPassword.vue");
const Auth = () => import("@/pages/auth/Auth.vue");

// User Routes
const UserSettings = () => import("@/pages/userSettings/UserSettings.vue");
const Security = () => import("@/pages/security/Security.vue");
const Companies = () => import("@/pages/companies/Companies.vue");
const Tour = () => import("@/pages/tour/Tour.vue");
const Referral = () => import("@/pages/referral/Referral.vue");

// company routes
const CompanyProfile = () => import("@/pages/companyProfile/CompanyProfile.vue");
const CompanySettings = () => import("@/pages/companySettings/CompanySettings.vue");
const WholesaleNeeds = () => import("@/pages/wholesaleNeeds/WholesaleNeeds.vue");
const BuyerRequests = () => import("@/pages/buyerRequests/BuyerRequests.vue");
const CompanyIntegrations = () => import("@/pages/companyIntegrations/CompanyIntegrations.vue");
const Payments = () => import("@/pages/payments/Payments.vue");
const Account = () => import("@/pages/account/Account.vue");
const Events = () => import("@/pages/events/Events.vue");
const Rejects = () => import("@/pages/inventory/rejects/Rejects.vue");
const Tasks = () => import("@/pages/tasks/Tasks.vue");
const Operations = () => import("@/pages/operations/Operations.vue");
const Operation = () => import("@/pages/operation/Operation.vue");
const Orders = () => import("@/pages/orders/Orders.vue");
const Activity = () => import("@/pages/activity/Activity.vue");
const Buyers = () => import("@/pages/buyers/Buyers.vue");
const Buyer = () => import("@/pages/buyer/Buyer.vue");
const Vendors = () => import("@/pages/vendors/Vendors.vue");
const Vendor = () => import("@/pages/vendor/Vendor.vue");
const Brands = () => import("@/pages/brands/Brands.vue");
const BulkDiscounts = () => import("@/pages/bulkDiscounts/BulkDiscounts.vue");
const DealFlows = () => import("@/pages/dealFlows/DealFlows.vue");
const Users = () => import("@/pages/users/Users.vue");
const Inventory = () => import("@/pages/inventory/Inventory.vue");
const StockAlerts = () => import("@/pages/stockAlerts/StockAlerts.vue");
const MappedRetailers = () => import("@/pages/stockAlerts/MappedRetailers.vue");
const UnmappedRetailers = () => import("@/pages/stockAlerts/UnmappedRetailers.vue");

const CompanyServicesPrivate = () => import("@/pages/companyServices/CompanyServicesPrivate.vue");
const CompanyAlerts = () => import("@/pages/companyAlerts/CompanyAlerts.vue");
const CompanyChat = () => import("@/pages/companyChat/CompanyChat.vue");
const Messages = () => import("@/pages/messages/Messages.vue");
const BuyerNeeds = () => import("@/pages/buyerNeeds/BuyerNeeds.vue");
const Tags = () => import("@/pages/tags/Tags.vue");
const PublicInvoice = () => import("@/pages/publicInvoice/PublicInvoice.vue");
const SupportLanding = () => import("@/pages/supportLanding/SupportLanding.vue");
const Support = () => import("@/pages/support/Support.vue");

const Awards = () => import("@/pages/awards/Awards.vue");
const Transporters = () => import("@/pages/transporters/Transporters.vue");
const ApiLandingPage = () => import("@/pages/companyApi/ApiLandingPage.vue");
const ApiDocumentsLandingPage = () => import("@/pages/companyApi/documentation/ApiDocumentsLandingPage.vue");
const MyVendors = () => import("@/pages/myVendors/MyVendors.vue");
const CompanyServiceLeads = () => import("@/pages/companyServices/CompanyServiceLeads.vue");
const CompanyServiceLead = () => import("@/pages/companyServices/CompanyServiceLead.vue");
const NotAuthorized = () => import("@/pages/NotAuthorized.vue");
const MarketplaceCart = () => import("@/pages/marketplace/cart/Cart.vue");
const MarketplaceSearch = () => import("@/pages/marketplace/search/MarketplaceSearch.vue");
const ThreePLLeads = () => import("@/pages/3pl/3pl-leads.vue");
const ThreePLOrders = () => import("@/pages/3pl/3pl-orders.vue");

 
const platform = APP_NAME;

const routes = [
  {
    path: "/login",
    alias: "/register",
    component: Auth,
    meta: { title: `${platform} - Login` },
    beforeEnter: (to, from, next) => {
      if (store.state.auth.loggedIn) {
        if (to.query && to.query.buyer_invitation) {
          next({ path: "/buyer-onboard", query: { redirect: to.fullPath } });
        } else if (to.query && to.query["brand-invitation"]) {
          next({ path: "/brand-onboard", query: to.query });
        } else {
          next({ path: "/", query: { redirect: to.fullPath } });
        }
      } else {
        next();
      }
    },
  },
  {
    path: "/reset-password/:token",
    meta: { title: `${platform} - Reset Password` },
    component: ResetPassword,
  },
  {
    path: "/menu/:sellerSlug/:buyerSlug/:token",
    meta: { title: `${platform} - Menu` },
    component: Storefront,
  },
  {
    path: "/terms-of-service",
    meta: { title: `${platform} - Terms Of Service` },
    component: TermsOfService,
  },
  {
    path: "/invoice/:anyrandomstring/:order_uuid", // this is temporary, old format. Can be removed after 9/1/22.
    meta: { title: `${platform} - Invoice` },
    component: PublicInvoice,
  },
  {
    path: "/invoice/:order_uuid",
    meta: { title: `${platform} - Invoice` },
    component: PublicInvoice,
  },

  // protected routes
  {
    path: "/",
    component: Main,
    beforeEnter: (to, from, next) => {
      if (!store.state.auth.loggedIn) {
        next({ path: "/login", query: { redirect: to.fullPath } });
      } else {
        next();
      }
    },
    children: [
      {
        path: "",
        meta: { title: `${platform}`, transition: "fade" },
        component: SlashPage,
        alias: ["/marketplace", "/dashboard", "/onboarding"],
      },
      {
        path: "/change-log",
        meta: { title: `${platform} - Change Log`, transition: "fade" },
        component: ChangeLog,
      },
      {
        path: "kiosk",
        component: Kiosk,
        meta: { requiresAuth: true, title: `${platform} - Kiosk`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (store.state.auth.kioskUser.id) {
            next({ path: "/", query: { redirect: to.fullPath } });
          } else {
            next();
          }
        },
      },
      {
        path: "user-settings",
        component: UserSettings,
        meta: { requiresAuth: true, title: `${platform} - User Settings`, transition: "fade" },
      },
      {
        path: "companies",
        component: Companies,
        meta: { requiresAuth: true, title: `${platform} - My Companies`, transition: "fade" },
      },
      {
        path: "security",
        component: Security,
        meta: { requiresAuth: true, title: `${platform} - Security`, transition: "fade" },
      },

      // support routes
      {
        path: "support",
        component: SupportLanding,
        meta: { requiresAuth: true, title: `${platform} - Support`, transition: "fade" },
      },
      {
        path: "kb",
        component: Support,
        meta: { requiresAuth: true, title: `${platform} - Knowledge Base`, transition: "fade" },
      },

      {
        path: "kb/:category/",
        component: Support,
        meta: { requiresAuth: true, title: `${platform} - Knowledge Base`, transition: "fade" },
      },

      {
        path: "kb/:category/:article",
        component: Support,
        meta: { requiresAuth: true, title: `${platform} - Knowledge Base`, transition: "fade" },
      },

      // buyer onboard
      {
        path: "buyer-onboard",
        component: BuyerOnboardCompanySelect,
        meta: { requiresAuth: true, title: `${platform} - Buyer Onboard`, transition: "fade" },
      },

      // brand onboard
      {
        path: "brand-onboard",
        component: BrandOnboardCompanySelect,
        meta: { requiresAuth: true, title: `${platform} - Brand Onboard`, transition: "fade" },
      },

      // not authorized
      {
        path: "not-authorized",
        component: NotAuthorized,
        meta: { requiresAuth: true, title: `${platform} - Not Authorized`, transition: "fade" },
      },

      // marketplace cart
      {
        path: "marketplace/cart",
        component: MarketplaceCart,
        meta: { requiresAuth: true, title: `${platform} - Marketplace`, transition: "fade" },
      },

      {
        path: "marketplace/search",
        component: MarketplaceSearch,
        meta: { requiresAuth: true, title: `${platform} - Marketplace`, transition: "fade" },
      },

      // Company Routes
      {
        path: "inventory",
        component: Inventory,
        meta: { requiresAuth: true, title: `${platform} - Inventory`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("view inventory", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },

      {
        path: "stock-alerts",
        component: StockAlerts,
        meta: { requiresAuth: true, title: `${platform} - Stock Alerts` },

        children: [
          {
            path: "",
            component: MappedRetailers,
            meta: {
              requiresAuth: true,
              title: `${platform} - Wholesale Needs`,
            },
          },
          {
            path: "unmapped-retailers",
            component: UnmappedRetailers,
            meta: { requiresAuth: true, title: `${platform} - Buyer Needs` },
          },
        ],
      },
      {
        path: "company/account",
        component: Account,
        meta: { requiresAuth: true, title: `${platform} - Account`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("update company account", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/tags",
        component: Tags,
        meta: { requiresAuth: true, title: `${platform} - Tags`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("update tags", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/settings",
        component: CompanySettings,
        meta: { requiresAuth: true, title: `${platform} - Settings`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("update company settings", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/integrations",
        component: CompanyIntegrations,
        meta: { requiresAuth: true, title: `${platform} - Integrations`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan(["update company integrations", "use metrc features"], store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "events",
        component: Events,
        meta: { requiresAuth: true, title: `${platform} - Events`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("update events", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "rejects",
        component: Rejects,
        meta: { requiresAuth: true, title: `${platform} - Rejects`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("update rejects", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "tasks",
        component: Tasks,
        meta: { requiresAuth: true, title: `${platform} - Tasks`, transition: "fade" },
      },
      {
        path: "tour/:type",
        component: Tour,
        meta: { requiresAuth: true, title: `${platform} - Tour`, transition: "fade" },
      },
      {
        path: "tour",
        component: Tour,
        meta: { requiresAuth: true, title: `${platform} - Tour`, transition: "fade" },
      },
      {
        path: "leads",
        component: Messages,
        meta: {
          requiresAuth: true,
          title: `${platform} - Leads`,
          transition: "fade",
        },
      },
      {
        path: "3pl/leads",
        component: ThreePLLeads,
        meta: {
          requiresAuth: true,
          title: `${platform} - Leads`,
          transition: "fade",
        },
      },
      {
        path: "3pl/orders",
        component: ThreePLOrders,
        meta: {
          requiresAuth: true,
          title: `${platform} - Orders`,
          transition: "fade",
        },
      },
      {
        name: "Chat",
        path: "chat",
        component: CompanyChat,
        meta: {
          requiresAuth: true,
          title: `${platform} - Chat`,
          transition: "fade",
        },
        props: true,
      },
      {
        path: "alerts",
        component: CompanyAlerts,
        meta: { requiresAuth: true, title: `${platform} - Alerts`, transition: "fade" },
      },
      {
        path: "reports",
        component: ReportingLanding,
        meta: { requiresAuth: true, title: `${platform} - Reports`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan(["view reports", "view buyer performance report sales rep"], store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/seller-orders",
        component: AdvancedReportingSellerOrders,
        meta: {
          requiresAuth: true,
          title: `${platform} - Seller Order Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/current-inventory",
        component: AdvancedReportingCurrentInventory,
        meta: {
          requiresAuth: true,
          title: `${platform} - Current Inventory Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/all-product-sales",
        component: AdvancedReportingAllProductSales,
        meta: {
          requiresAuth: true,
          title: `${platform} - All Product Sales Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/buyer-orders",
        component: AdvancedReportingBuyerOrders,
        meta: {
          requiresAuth: true,
          title: `${platform} - Buyer Order Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (
            userCan("view reports", store.state.auth.user) &&
            (store.state.auth.user.current_company.subscription == "buyer" || store.state.auth.user.current_company.subscription == "seller")
          ) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/buyer-recent-orders",
        component: BasicReportingBuyerRecentOrders,
        meta: {
          requiresAuth: true,
          title: `${platform} - Buyer Recent Orders Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "buyer") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/basic-seller-orders",
        component: BasicReportingSellerOrders,
        meta: {
          requiresAuth: true,
          title: `${platform} - Seller Order Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/basic-all-product-sales",
        component: BasicReportingProductSales,
        meta: {
          requiresAuth: true,
          title: `${platform} - All Product Sales Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/basic-buyer-orders",
        component: BasicReportingBuyerOrders,
        meta: {
          requiresAuth: true,
          title: `${platform} - Buyer Order Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (
            userCan("view reports", store.state.auth.user) &&
            (store.state.auth.user.current_company.subscription == "buyer" || store.state.auth.user.current_company.subscription == "seller")
          ) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/inventory-overview",
        component: BasicReportingInventoryOverview,
        meta: {
          requiresAuth: true,
          title: `${platform} - Buyer Order Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/samples-report",
        component: BasicSellerSamplesReport,
        meta: {
          requiresAuth: true,
          title: `${platform} - Seller Order Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/brands-report",
        component: BasicSellerBrandsReport,
        meta: {
          requiresAuth: true,
          title: `${platform} - Seller Brands Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/product-performance",
        component: PerformanceReportingProduct,
        meta: {
          requiresAuth: true,
          title: `${platform} - Buyer Order Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/buyer-performance",
        component: PerformanceReportingBuyer,
        meta: {
          requiresAuth: true,
          title: `${platform} - Buyer Performance Reports`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (
            userCan(["view reports", "view buyer performance report sales rep"], store.state.auth.user) &&
            store.state.auth.user.current_company.subscription == "seller"
          ) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/team-sales",
        component: PerformanceReportingRepSales,
        meta: {
          requiresAuth: true,
          title: `${platform} - Sales Team Performance Report`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (userCan("view reports", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/brand-distributor-inventory",
        component: BasicReportingBrandCompanyInventory,
        meta: {
          requiresAuth: true,
          title: `${platform} - Brand Distributor Inventory`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (
            userCan("view reports", store.state.auth.user) &&
            (store.state.auth.user.current_company.is_brand || store.state.auth.user.current_company.subscription == "brand")
          ) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "reports/brand-distributor-orders",
        component: BasicReportingBrandCompanyOrders,
        meta: {
          requiresAuth: true,
          title: `${platform} - Brand Distributor Orders`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (
            userCan("view reports", store.state.auth.user) &&
            (store.state.auth.user.current_company.is_brand || store.state.auth.user.current_company.subscription == "brand")
          ) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/payments",
        component: Payments,
        meta: { requiresAuth: true, title: `${platform} - Payments`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("update payment settings", store.state.auth.user) && store.state.auth.user.current_company.subscription != "service provider") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/brands",
        component: Brands,
        meta: { requiresAuth: true, title: `${platform} - Brands`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/awards",
        component: Awards,
        meta: { requiresAuth: true, title: `${platform} - Awards`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/profile",
        component: CompanyProfile,
        meta: { requiresAuth: true, title: `${platform} - Company Profile`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("view company profile", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },

      {
        path: "company/users",
        component: Users,
        meta: { requiresAuth: true, title: `${platform} - Users`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("update users", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/deal-flows",
        component: DealFlows,
        meta: { requiresAuth: true, title: `${platform} - Deal Flows`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("update deal flows", store.state.auth.user) && store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/bulk-discounts",
        component: BulkDiscounts,
        meta: { requiresAuth: true, title: `${platform} - Bulk Discounts`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/operations",
        component: Operations,
        meta: { requiresAuth: true, title: `${platform} - Operations`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("view company operations", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/operations/operation/:uuid",
        component: Operation,
        meta: { requiresAuth: true, title: `${platform} - Operation`, transition: "fade" },
        props: true,
        beforeEnter: (to, from, next) => {
          if (userCan("view company operations", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        name: "ApiLandingPage",
        path: "company/api",
        component: ApiLandingPage,
        meta: { requiresAuth: true, title: `${platform} - API`, transition: "fade" },
        props: true,
        beforeEnter: (to, from, next) => {
          if (userCan(["manage api tokens", "view api tokens"], store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        name: "ApiDocumentsLandingPage",
        path: "company/api/documentation",
        component: ApiDocumentsLandingPage,
        meta: { requiresAuth: true, title: `${platform} - API documentation`, transition: "fade" },
        props: true,
        beforeEnter: (to, from, next) => {
          next();
        },
      },
      {
        path: "wholesale-needs",
        component: WholesaleNeeds,
        meta: { requiresAuth: true, title: `${platform} - Wholesale Needs`, transition: "fade" },

        children: [
          {
            path: "",
            component: BuyerRequests,
            meta: {
              requiresAuth: true,
              title: `${platform} - Wholesale Needs`,
              transition: "fade",
            },
          },
          {
            path: "buyer-needs",
            component: BuyerNeeds,
            meta: { requiresAuth: true, title: `${platform} - Buyer Needs`, transition: "fade" },
          },
        ],
      },
      {
        path: "orders",
        component: Orders,
        props: true,
        meta: { requiresAuth: true, title: `${platform} - Orders`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (
            userCan(["view received orders", "view own orders", "view all orders"], store.state.auth.user) &&
            store.state.auth.user.current_company.subscription != "service provider"
          ) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        name: "ReceivingOrder",
        path: "orders/receiving/:selectedReceivingOrderUuid",
        component: Orders,
        props: true,
        meta: { requiresAuth: true, title: `${platform} - Orders`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan(["view received orders"], store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        name: "ShippingOrder",
        path: "orders/shipping/:selectedShippingOrderUuid",
        component: Orders,
        props: true,
        meta: { requiresAuth: true, title: `${platform} - Orders`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan(["view own orders", "view all orders"], store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "buyers",
        component: Buyers,
        meta: { requiresAuth: true, title: `${platform} - Buyers`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        name: "Buyer",
        path: "buyers/buyer/:uuid",
        component: Buyer,
        meta: { requiresAuth: true, title: `${platform} - Buyer`, transition: "fade" },
        props: true,
        beforeEnter: (to, from, next) => {
          if (store.state.auth.user.current_company.subscription == "seller") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/activity",
        component: Activity,
        meta: { requiresAuth: true, title: `${platform} - Activity`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("view activity audit", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "company/transporters",
        component: Transporters,
        meta: { requiresAuth: true, title: `${platform} - Activity`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("update transporters", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        path: "vendors",
        component: Vendors,
        meta: { requiresAuth: true, title: `${platform} - Vendors`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("view vendors", store.state.auth.user) && store.state.auth.user.current_company.subscription != "service provider") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
        children: [
          {
            path: "",
            name: "MyVendors",
            component: MyVendors,
            meta: { requiresAuth: true, title: `${platform} - My Vendors`, transition: "fade" },
            beforeEnter: (to, from, next) => {
              if (userCan("view vendors", store.state.auth.user)) {
                next();
              } else {
                next({
                  path: "/not-authorized",
                  query: { redirect: to.fullPath },
                });
              }
            },
          },
          {
            path: "referral",
            component: Referral,
            name: "Referral",
            meta: { requiresAuth: true, title: `${platform} - Refer a Vendor`, transition: "fade" },
          },
        ],
      },
      {
        path: "vendors/vendor/:uuid",
        component: Vendor,
        meta: { requiresAuth: true, title: `${platform} - Vendor`, transition: "fade" },
        props: true,
        beforeEnter: (to, from, next) => {
          if (userCan("view vendors", store.state.auth.user) && store.state.auth.user.current_company.subscription != "service provider") {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        name: "CompanyServiceLeads",
        path: "service-leads",
        component: CompanyServiceLeads,
        props: true,
        meta: {
          requiresAuth: true,
          title: `${platform} - Service Leads`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (
            userCan("view company services", store.state.auth.user) &&
            store.state.auth.user.current_company.subscription != "buyer" &&
            (store.state.auth.user.current_company.subscription == "service provider" ||
              (store.state.auth.user &&
                store.state.auth.user.current_company &&
                store.state.auth.user.current_company.config &&
                store.state.auth.user.current_company.config.can_be_service_provider) ||
              (store.getters["companies/currentCompany"].config && store.getters["companies/currentCompany"].config.can_be_service_provider))
          ) {
            next();
          } else {
            next({ path: "/", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        name: "CompanyServiceLead",
        path: "service-leads/:uuid",
        component: CompanyServiceLead,
        props: true,
        meta: {
          requiresAuth: true,
          title: `${platform} - Service Lead`,
          transition: "fade",
        },
        beforeEnter: (to, from, next) => {
          if (
            userCan("view company services", store.state.auth.user) &&
            store.state.auth.user.current_company.subscription != "buyer" &&
            (store.state.auth.user.current_company.subscription == "service provider" ||
              (store.state.auth.user &&
                store.state.auth.user.current_company &&
                store.state.auth.user.current_company.config &&
                store.state.auth.user.current_company.config.can_be_service_provider) ||
              (store.getters["companies/currentCompany"].config && store.getters["companies/currentCompany"].config.can_be_service_provider))
          ) {
            next();
          } else {
            next({ path: "/", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        name: "CompanyServices",
        path: "company-services",
        component: CompanyServicesPrivate,
        props: true,
        meta: { requiresAuth: true, title: `${platform} - Your Services`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (
            userCan("view company services", store.state.auth.user) &&
            (store.state.auth.user.current_company.subscription != "service provider" ||
              store.state.auth.user.current_company.subscription != "seller")
          ) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      {
        name: "ServicesMarkeplace",
        path: "services",
        component: ServicesMarkeplace,
        props: true,
        meta: { requiresAuth: true, title: `${platform} - Services`, transition: "fade" },
        beforeEnter: (to, from, next) => {
          if (userCan("view external services", store.state.auth.user)) {
            next();
          } else {
            next({ path: "/not-authorized", query: { redirect: to.fullPath } });
          }
        },
      },
      { path: "*", component: NotFound, meta: { requiresAuth: true } }, // if not logged in, any route will redirect to login. if logged in, catch and show 404
    ],
  },
];

export default routes;
