<template>
  <div
    v-if="subtitle"
    id="noitems-subtitle-component"
  >
    <h5
      v-for="(segment, subtitleIndex) in chunkedSubtitles"
      :key="`subtitle-${subtitleIndex}`"
      class="subtitle text-secondary"
    >
      <div
        v-for="(item, segmentIndex) in segment"
        :key="`segment-${segmentIndex}`"
        class="mr-1"
      >
        <div
          v-if="isItem(item, 'add button')"
          class="plus-button"
        >
          +
        </div>
        <div
          v-else-if="isItem(item, 'button text') && buttonText"
          class="font-weight-bold"
        >
          {{ buttonText }}
        </div>
        <ImgIcon
          v-else-if="isItem(item, 'tutorial')"
          icon="pop-icon"
        />
        <template v-else-if="isItem(item, 'link')">
          <a
            v-if="external"
            :href="execRegexTemplate('link', item)[2]"
          >
            {{ execRegexTemplate("link", item)[1] }}
          </a>
          <router-link
            v-else
            :to="execRegexTemplate('link', item)[2]"
          >
            {{ execRegexTemplate("link", item)[1] }}
          </router-link>
        </template>
        <template v-else>
          {{ item }}
        </template>
      </div>
    </h5>
  </div>
</template>

<script>
import ImgIcon from "@/components/common/icons/ImgIcon.vue";

export default {
  components: {
    ImgIcon,
  },
  props: {
    subtitle: { type: [String, Array] },
    buttonText: { type: String, require: false },
    external: { type: Boolean },
  },
  data() {
    return {
      templates: [
        {
          label: "add button",
          regex: "_\\+(.*?)",
        },
        {
          label: "button text",
          regex: "_btn(.*?)",
        },
        {
          label: "tutorial",
          regex: "_tutorial(.*?)",
        },
        {
          label: "link",
          regex: "\\[(.*?)\\]\\((.*?)\\)",
        },
      ],
    };
  },
  computed: {
    chunkedSubtitles() {
      return Array.isArray(this.subtitle)
        ? this.subtitle.map((segment) => this.chunkStringByTemplates(segment))
        : [this.chunkStringByTemplates(this.subtitle)];
    },
  },
  methods: {
    getRegexTemplate(templateLabel) {
      // ? returns a defined regexTemplate if found
      return this.templates.find((template) => template.label.includes(templateLabel));
    },
    execRegexTemplate(templateLabel, string) {
      // ? returns the regex exec for a particular template + string.
      const selectedRegex = this.getRegexTemplate(templateLabel);
      return !!selectedRegex && new RegExp(selectedRegex.regex).exec(string);
    },
    isItem(item, templateLabel) {
      // ? returns boolean if the item is "equal" to the template
      const selectedTemplate = this.getRegexTemplate(templateLabel);
      if (selectedTemplate) {
        return !!new RegExp(selectedTemplate.regex).exec(item);
      }
      return false;
    },
    chunkStringByTemplates(string, templates = this.templates) {
      // ? this function splits a string by the defined templates and returns that result into an array

      const splitBy = (key, value) =>
        value.split(key).reduce((store, item) => {
          // ? splitBy, a helper function that splits a string into three segements:
          // * 1. before-key-segment, 2. key-segment, 3. after-key-segment
          if (store.length % 2) store.push(key);
          if (item) store.push(item);
          return store;
        }, []);

      return templates
        .map(({ regex }) => {
          const result = new RegExp(regex).exec(string);
          return { result, splitKey: result && result[0] };
        })
        .reduce(
          (store, { splitKey }) => {
            store
              .map((substring) => (substring.includes(splitKey) ? splitBy(splitKey, substring) : substring))
              .forEach((substring, index) => {
                if (Array.isArray(substring)) {
                  store.splice(index, 1, ...substring);
                }
              });

            return store;
          },
          [string],
        );
    },
  },
};
</script>

<style lang="scss" scoped>
#noitems-subtitle-component {
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  align-items: inherit;
  max-width: max-content;
  max-height: max-content;

  .subtitle {
    display: flex;
    flex-flow: wrap;
    text-overflow: wrap;

    .plus-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      user-select: none;
      min-width: 1.75rem;
      min-height: 1.5rem;
      background: #84a263;
      border-radius: 0.25rem;
      color: white;
      font-size: 0.9rem;
    }
  }
}
</style>
