<template>
  <div id="noitems-component">
    <template v-for="(layoutType, index) in layouts">
      <div v-if="displayLayout(layoutType)" :id="layoutType" :key="`${layoutType}-${index}`" :class="debug && 'debug'" class="noitems-container">
        <div class="info">
          <h2 id="title" class="font-weight-bold text-primary">
            {{ title }}
          </h2>
          <h5 v-if="displayTutorial" class="d-flex flex-wrap text-secondary">
            <span>To get started, click the</span>
            <ImgIcon class="ml-1 mr-1" icon="pop-icon" />
            <span>to go through a quick tutorial.</span>
          </h5>
          <NoItemsSubtitle v-if="subtitle" :subtitle="subtitle" :class="subtitleClass" :button-text="actionButtonText" :external="external" />
          <slot />
          <BushelBtn
            v-if="actionButtonText"
            id="button"
            :variant="actionButtonVariant"
            class="nowrap"
            :class="actionButtonClass"
            :style="actionButtonStyle"
            :disabled="actionButtonDisabled"
            @click="$emit('click')"
          >
            {{ actionButtonText }}
          </BushelBtn>
        </div>
        <div id="image">
          <div>
            <img :src="image" alt="No Items Graphic" class="img-fluid" style="width: 344px" :class="imageClass" />
          </div>
        </div>
        <div v-if="debug" class="position-absolute top-0 left-0" style="opacity: 0.5; color: coral">
          <h1>{{ layoutType }}</h1>
        </div>
      </div>
    </template>
    <NoItemsNoLayout :layout="layout" :layouts="layouts" />
  </div>
</template>

<script>
import ImgIcon from "@/components/common/icons/ImgIcon.vue";
import NoItemsSubtitle from "./templates/NoItemsSubtitle.vue";
import NoItemsNoLayout from "./templates/NoItemsNoLayout.vue";

export default {
  components: {
    ImgIcon,
    NoItemsSubtitle,
    NoItemsNoLayout,
  },
  props: {
    title: { type: String, require: true },
    layout: { type: String, default: "bottom" },
    debug: { type: Boolean, default: false },
    titleClass: { type: String },
    subtitle: { type: [String, Array] },
    subtitleClass: { type: String },
    imageClass: { type: String },
    actionButtonClass: { type: String },
    actionButtonStyle: { type: [Object, String], default: "width: 10rem" },
    actionButtonText: { type: String },
    actionButtonVariant: { type: String, default: "callout" },
    actionButtonDisabled: { type: Boolean, default: false },
    tutorial: { type: Boolean, default: undefined },
    apexTutorial: { type: Boolean, default: false },
    bushelTutorial: { type: Boolean, default: false },
    external: { type: Boolean, default: false },
  },
  emits: ["click"],
  data() {
    return {
      industry: APP_INDUSTRY,
      layouts: ["top", "bottom", "left", "right"],
    };
  },
  computed: {
    image() {
      return this.industry == "cannabis" ? "/img/misc/apex-no-items.png" : "/img/misc/bushel-no-items.png";
    },
    hasLayout() {
      return !!this.layouts.includes(this.layout);
    },
    displayTutorial() {
      if (this.tutorial !== undefined) return this.tutorial;
      return this.industry == "cannabis" ? this.apexTutorial : this.bushelTutorial;
    },
  },
  methods: {
    displayLayout(layoutType) {
      const { layout, debug } = this;
      return !!(layout.includes(layoutType) || debug);
    },
  },
};
</script>

<style lang="scss" scoped>
#noitems-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 0;

  .noitems-container {
    display: grid;
    position: relative;

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 1rem;

      #title {
        grid-area: title;
        max-width: max-content;
        text-align: inherit;
      }

      #button {
        grid-area: button;
      }
    }

    #image {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-area: image;
    }

    &.debug {
      border-bottom: 2px solid black;
    }

    /* define/modify layouts bellow here */

    &#top {
      grid-template-areas:
        "info"
        "image";
    }

    &#bottom {
      grid-template-areas:
        "image"
        "info";
    }

    &#left {
      grid-template-areas:
        "info"
        "image";

      @media (min-width: 768px) {
        grid-template-areas: "info image";

        .info {
          align-items: flex-start;
          text-align: left;
        }
      }
    }

    &#right {
      grid-template-areas:
        "info"
        "image";

      @media (min-width: 768px) {
        grid-template-areas: "image info";

        .info {
          align-items: flex-start;
          text-align: left;
        }
      }
    }
  }
}
</style>
