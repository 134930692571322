<template>
  <div
    v-show="submitting"
    class="fullPageOverlaySubmit"
  >
    <div class="loader">
      <slot name="loader-type" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    submitting: { type: Boolean, required: true },
  },
};
</script>
