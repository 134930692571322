<template>
  <div
    v-if="!hasLayout"
    class="d-flex flex-column justify-content-center align-items-center"
  >
    <h3 class="text-primary">
      layout
      <span class="font-weight-bold">{{ layout }}</span>
      wasn't found on
      <span class="font-weight-bold">NoItems</span>
      Component!
    </h3>
    <h5 class="text-secondary">
      create a new layout called
      <span class="font-weight-bold">{{ layout }}</span>
      or use an existing one:
    </h5>
    <div class="d-flex flex-wrap">
      <div
        v-for="layoutType in layouts"
        :key="layoutType"
        class="font-weight-bold p-1 m-1 background-coral"
        style="background: coral; border-radius: 0.25rem"
      >
        {{ layoutType }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    layout: { type: String, require: true },
    layouts: { type: Array, require: true },
  },
  computed: {
    hasLayout() {
      return !!(this.layouts.includes(this.layout));
    },
  },
};
</script>

<style>

</style>
