<template>
  <router-view v-slot="{ Component }">
    <Transition name="fade">
      <component :is="Component" />
    </Transition>
  </router-view>
</template>

<script>
import { RouterView } from "vue-router";

export default {
  components: { RouterView },
};
</script>
