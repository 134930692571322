<template>
  <b-modal id="doc-viewer-modal" body-class="p-0" :visible="show" :scrollable="false" footer-class="w-100 border-top" size="xl" @hide="closeModal">
    <template #modal-title>
      <p class="m-0">
        {{ doc.name }}
      </p>
      <span class="separator" />
      <p class="m-0 border-left pl-3 ml-1">
        {{ doc.label }}
      </p>
    </template>
    <div v-if="!docLoaded && !error">
      <Loading />
    </div>
    <div v-else-if="!docLoaded && error">
      <div class="alert alert-warning">Sorry, we had an unexpected error loading this file. Please attempt to download it instead.</div>
    </div>
    <div v-else class="text-center overflow-auto" style="max-height: 70vh">
      <div v-if="doc.ext === '.pdf' && docLoaded" class="pdf-preview-container">
        <div class="pdf-icon-wrapper">
          <i class="far fa-file-pdf pdf-icon"></i>
          <div class="pdf-filename">{{ doc.name }}</div>
        </div>
        <div class="pdf-actions mt-3 p-2 text-center">
          <BushelBtn flat variant="primary" class="mx-2" @click="downloadPdf"> <i class="fas fa-download mr-1"></i> Download PDF </BushelBtn>
          <BushelBtn flat variant="secondary" class="mx-2" @click="openInNewWindow">
            <i class="fas fa-external-link-alt mr-1"></i> Open in New Window
          </BushelBtn>
        </div>
      </div>
      <img v-else :src="src" :alt="doc.name" :style="{ maxWidth: '100%' }" />
      <SignatureList v-if="signable && doc && doc.uuid" :key="sigKey" :doc="doc" />
    </div>
    <div v-if="isSigning">
      <div v-if="!consentToSign" id="consentToSign" ref="consentToSign">
        <div class="alert alert-warning mt-2 mb-0">
          By clicking 'Accept' below, you are agreeing to do business electronically in accordance with the United States Electronic Signatures in
          Global and National Commerce (ESIGN) Act, and the Uniform Electronic Transactions Act (UETA). Be aware that US courts have held that
          electronic signatures constitute a valid signature. These records will be maintained for the life of our company and you may access them
          through any modern web browser and your account. If your company is not based in the United States, the company you are doing business with
          has confirmed willingness to allow electronic signatures in accordance with the ESIGN act and UETA. If you do not consent to sign
          electronically you may alternatively download and print this document and physically sign it before re-uploading.
        </div>
      </div>
      <div v-else id="sigpad" class="mt-4 mx-4">
        <div class="row">
          <BushelTextInput
            v-model="signerForm.company.value"
            label-active="Company Name"
            class="col"
            :error="signerForm.company.error"
            :error-text="signerForm.company.errorMessage"
            @input="signerFormValidator"
          />
          <BushelTextInput
            v-model="signerForm.name.value"
            label-active="Signer Name"
            class="col"
            :error="signerForm.name.error"
            :error-text="signerForm.name.errorMessage"
            @input="signerFormValidator"
          />
        </div>
        <p class="d-inline doc-type-label mr-1 nowrap">Name: {{ signerForm.name.value }}</p>
        <p class="d-inline doc-type-label mr-1 nowrap">Company: {{ signerForm.company.value }}</p>
        <p class="d-inline doc-type-label nowrap">Date: {{ datetimeLocalized(new Date()) }}</p>
        <Vue3Signature ref="signature" :style="{ border: '1px dashed #4f6281' }" :sig-option="sigOption" />
      </div>
    </div>

    <template #modal-footer>
      <BushelBtn v-if="!isSigning" flat variant="secondary" @click="closeModal"> Close </BushelBtn>
      <BushelBtn v-if="doc.uuid && signable" flat :variant="!isSigning ? 'success' : 'secondary'" @click="toggleSig">
        {{ !isSigning ? "Sign" : "Cancel" }}
      </BushelBtn>
      <BushelBtn v-if="isSigning && !consentToSign" flat variant="success" @click="consentToSign = true"> Accept </BushelBtn>
      <div v-if="isSigning && consentToSign">
        <BushelBtn flat variant="warning" @click="clearSig"> Clear </BushelBtn>
        <BushelBtn flat variant="warning" @click="undoSig"> Undo </BushelBtn>
        <BushelBtn flat variant="success" :disabled="signerForm.company.error || signerForm.name.error" @click="saveSig"> Save </BushelBtn>
      </div>
    </template>
  </b-modal>
</template>
<script>
// import pdf from "pdfvuer";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue3Signature from "vue3-signature";
import SignatureList from "./SignatureList.vue";
import * as api from "@/services/api";
import BushelTextInput from "@/components/common/forms/BushelTextInput.vue";
import { datetimeLocalized } from "@/services/filters";

export default {
  components: {
    SignatureList,
    Vue3Signature,
    BushelTextInput,
  },
  props: {
    downloadPath: { type: String, required: true },
    show: { type: Boolean, required: true },
    signable: { type: Boolean, default: false },
    doc: { type: Object, required: true },
  },
  emits: ["documentModalClosed", "signatureCreated"],
  data() {
    return {
      sigKey: 1,
      docLoaded: false,
      src: null,
      isSigning: false,
      error: false,
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      iframeHtml: "",
      consentToSign: false,
      sigOption: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      signerForm: {
        name: {
          value: "",
          error: false,
          errorMessage: "",
        },
        company: {
          value: "",
          error: false,
          errorMessage: "",
        },
      },
    };
  },
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    ...mapGetters({
      currentCompany: "companies/currentCompany",
    }),
  },
  watch: {
    page(p) {
      if (
        window.pageYOffset <= this.findPos(document.getElementById(p)) ||
        (document.getElementById(p + 1) && window.pageYOffset >= this.findPos(document.getElementById(p + 1)))
      ) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    },
  },
  mounted() {
    this.loadFile();
    this.resetSignerForm();
  },
  methods: {
    datetimeLocalized,
    ...mapActions({ setSnackbar: "ui/setSnackbar" }),
    loadFile() {
      if (this.doc.uuid) {
        api.get(`${this.downloadPath}/${this.doc.uuid}`).then(
          (response) => {
            if (response.data) {
              if (this.doc.ext === ".pdf") {
                // Just use data URL directly
                this.src = `data:application/pdf;base64,${response.data}`;
                this.docLoaded = true;
              } else {
                this.src = `data:${this.doc.mime_type};base64,${response.data}`;
                this.docLoaded = true;
              }
            } else {
              this.error = true;
            }
          },
          (error) => {
            this.error = true;
          },
        );
      } else {
        this.src = this.doc.file;
        this.docLoaded = true;
      }
    },
    closeModal() {
      this.$emit("documentModalClosed");
    },
    downloadPdf() {
      if (this.src) {
        const link = document.createElement("a");
        link.href = this.src;
        link.download = this.doc.name || "document.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    openInNewWindow() {
      if (this.src) {
        // Convert data URL to blob URL for better performance in new window
        const blobUrl = this.convertDataUrlToBlob(this.src);
        if (blobUrl) {
          window.open(blobUrl, "_blank");
        } else {
          // Fallback to direct data URL if conversion fails
          window.open(this.src, "_blank");
        }
      }
    },
    convertDataUrlToBlob(data) {
      try {
        let base64;

        // Handle various formats of data URLs
        if (data.indexOf("data:") === 0) {
          // It's a properly formatted data URL
          if (data.indexOf(";base64,") !== -1) {
            // Standard format "data:type;base64,content"
            base64 = data.split(";base64,")[1].trim();
          } else if (data.indexOf(",") !== -1) {
            // Alternative format "data:type,content"
            base64 = data.split(",")[1].trim();
          } else {
            throw new Error("Couldn't find base64 data in URL");
          }
        } else {
          // Maybe it's just the base64 content directly
          base64 = data.trim();
        }

        // Convert to binary
        const binary = atob(base64);

        // Create array buffer
        const array = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) {
          array[i] = binary.charCodeAt(i);
        }

        // Create blob and convert to URL
        const blob = new Blob([array], { type: "application/pdf" });
        let blobUrl = URL.createObjectURL(blob);

        return blobUrl;
      } catch (e) {
        console.error("Error converting data URL to blob:", e);
        // Add more debugging info
        console.log("Data URL format (first 50 chars):", data.substring(0, 50));
        return null;
      }
    },

    findPos(obj) {
      return obj.offsetTop;
    },
    saveSig() {
      const png = this.$refs.signature.save();
      // const jpeg = _this.$refs.signature.save('image/jpeg');
      // const svg = _this.$refs.signature.save('image/svg+xml');

      const upload = {
        signature: png,
        mimeType: "image/png",
        signedDocument: this.doc.id,
        consentToDoBusinessElectronically: this.consentToSign,
        signerName: this.signerForm.name.value,
        signerCompany: this.signerForm.company.value,
      };

      this.resetSignerForm();

      api.post("signatures", upload).then(
        (response) => {
          if (response.data && response.data.id) {
            this.sigKey += 1;
            this.$emit("signatureCreated", upload);
          } else {
            this.setSnackbar({
              variant: "danger",
              title: "Error",
              message: "There was an error signing this document. Please verify you can see your signature on it.",
            });
          }
        },
        (error) => {
          this.setSnackbar({
            variant: "danger",
            title: "Error",
            message: "There was an error signing this document. Please verify you can see your signature on it.",
          });
        },
      );

      this.consentToSign = false;
      this.isSigning = false;
    },
    toggleSig() {
      this.isSigning = !this.isSigning;
      this.consentToSign = false;

      setTimeout(() => {
        const element = document.getElementById("consentToSign");
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
      }, 500);
    },
    clearSig() {
      this.$refs.signature.clear();
    },
    undoSig() {
      this.$refs.signature.undo();
    },
    signerFormValidator() {
      const setError = (name, status, message) => {
        this.signerForm[name].error = status;
        this.signerForm[name].errorMessage = message;
      };

      if (this.signerForm.company.value.length < 1) {
        setError("company", true, "Company Name is Required");
      } else {
        setError("company", false, "");
      }

      if (this.signerForm.name.value.length < 1) {
        setError("name", true, "Signer Name is Required");
      } else {
        setError("name", false, "Signer Name is Required");
      }
    },
    resetSignerForm() {
      Object.keys(this.signerForm).forEach((key) => {
        if (["company", "name"].includes(key)) {
          this.signerForm[key].value = "";
        }
      });
      this.signerForm.name.value = this.user.name;
      this.signerForm.company.value = this.currentCompany.name;
      this.signerFormValidator();
    },
  },
};
</script>

<style scoped lang="scss">
.doc-type-label {
  background-color: #eceef1;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 0px 5px;
  margin: 2px 0px;
  border-radius: 4px;
}

#pdfvuer {
  height: 100%;
  overflow: scroll;
}

.pdf-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.pdf-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.pdf-icon {
  font-size: 72px;
  color: #e74c3c;
  margin-bottom: 15px;
}

.pdf-filename {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-align: center;
  word-break: break-word;
  max-width: 180px;
}

.pdf-actions {
  background-color: #f8f9fa;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
  max-width: 400px;
}
</style>
