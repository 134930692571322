<template>
  <BushelCard
    body-class="py-1"
    body-style="background-image: linear-gradient(to right, #fef5cd, #f6e5a2, #f1c786, #f19978, #f0686a"
    :class="cardClass"
  >
    <div class="row py-3">
      <div class="col-12 col-lg-8 d-flex my-auto">
        <div
          class="d-none d-lg-inline-block my-auto mr-3"
          style="height: 80px; width: 80px"
        >
          <img
            style="max-height: 100%"
            src="img/icons/lock-alert-yellow.svg"
          >
        </div>
        <div class="d-inline-block my-auto">
          <h2 class="mb-0">
            {{ title }}
          </h2>
          <p
            v-if="subtext"
            class="mb-0"
          >
            {{ subtext }}
          </p>
          <p
            v-if="thirdText"
            :class="thirdTextClass"
          >
            {{ thirdText }}
          </p>
        </div>
      </div>
      <div
        v-if="$slots.default"
        class="col-12 col-lg-4 my-auto"
      >
        <div
          :class="slotAreaClass"
          :style="slotAreaStyle"
        >
          <slot />
        </div>
      </div>
    </div>
  </BushelCard>
</template>
<script>
export default {
  components: {},
  props: {
    title: { type: String, default: "Not Authorized" },
    subtext: { type: String },
    thirdText: { type: String },
    thirdTextClass: { type: String, default: "text-sm mb-0" },
    slotAreaStyle: { type: String, default: "width: 250px; background: #ffffff63;" },
    slotAreaClass: { type: String, default: "my-auto float-lg-right text-center rounded p-2 mx-auto" },
    cardClass: { type: String, default: "mb-3" },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
