import { createWebHistory, createRouter } from "vue-router";
import * as Sentry from "@sentry/vue";
import routes from "@/routes";
import store from "@/store";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.onError((error, to) => {
  // if (/loading chunk \d* failed./i.test(error.message)) {
  // i doubt this is still needed. This was webpack, not vite
  // do a hard reload of the new path instead of client side routing
  // if (!to?.fullPath) {
  //   window.location.reload();
  // } else {
  //   window.location = to.fullPath;
  // }
  // }

  if (error.message) {
    Sentry.captureMessage(`Router error: ${error.message}`);
  }

  if (error.message.includes("Failed to fetch dynamically imported module") || error.message.includes("Importing a module script failed")) {
    // do a hard reload of the new path instead of client side routing
    Sentry.captureMessage("Failed to fetch dynamically imported module captured in router");

    // if (!to?.fullPath) {
    //   window.location.reload();
    // } else {
    //   window.location = to.fullPath;
    // }
  }

  if (error.message.includes("Unable to preload CSS")) {
    // do a hard reload of the new path instead of client side routing
    Sentry.captureMessage("Unable to preload CSS captured in router");

    // if (!to?.fullPath) {
    //   window.location.reload();
    // } else {
    //   window.location = to.fullPath;
    // }
  }

  /// *****************************************************
  // If the window.location method doesn't work, try this:
  // sessionStorage.setItem('redirectAfterReload', to.fullPath);

  // // Do a full page reload to clear all cached resources
  // window.location.reload(true);

  // Check if we need to redirect after a reload (goes in app.js or layout)
  // const redirectPath = sessionStorage.getItem('redirectAfterReload');
  // if (redirectPath) {
  //   sessionStorage.removeItem('redirectAfterReload');
  //   router.push(redirectPath);
  // }

  /// *****************************************************
});

router.beforeEach((to, from, next) => {
  // set page title
  document.title = to.meta.title;

  if (from && from.path && to && to.path) {
    // this allows us to clear the vendors page if we are leaving that page and not looking at a vendor,
    // and if leaving a vendor and not going back to vendors
    if ((from.path.includes("/vendors") || from.path.includes("/marketplace")) && !to.path.includes("/vendors")) {
      store.dispatch("vendors/reset");
    }
  }

  // see if user is logged in.
  const { loggedIn } = store.state.auth;

  // handle order routing to public invoice
  if (!loggedIn && to.path.includes("/orders/")) {
    if (to.path.includes("receiving/")) {
      // let order_uuid = to.params.order_uuid;
      const orderUUID = /[^/]*$/.exec(to.path)[0];
      next({ path: `/invoice/${orderUUID}?order_type=receiving`, replace: true, query: { redirect: to.fullPath } });
    } else if (to.path.includes("shipping/")) {
      const orderUUID = /[^/]*$/.exec(to.path)[0];
      next({ path: `/invoice/${orderUUID}?order_type=shipping`, replace: true, query: { redirect: to.fullPath } });
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn && to.path !== "/login") {
    // check if allowed to see route
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else if (
    // prevent access to main company pages if no main current_company_id exists for user
    store.state.auth.user.current_company_id === null &&
    !["/companies", "/profile", "/logout", "/security"].includes(to.path)
  ) {
    next({ path: "/companies", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
