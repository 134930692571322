import { computePosition, offset, flip, shift, arrow, autoUpdate, inline } from "@floating-ui/dom";
import { nextTick } from "vue";

function updatePosition(el, tooltip, placement, arrowElement, gap = 10) {
  nextTick(() => {
    computePosition(el, tooltip, {
      placement: placement || "bottom",
      middleware: [inline(), offset(gap), flip(), shift({ padding: 10 }), arrow({ element: arrowElement })],
    }).then(({ x, y, placement: computedPlacement, middlewareData }) => {
      Object.assign(tooltip.style, {
        left: `${x}px`,
        top: `${y}px`,
      });

      const { x: arrowX, y: arrowY } = middlewareData.arrow || {};

      const staticSide = {
        top: "bottom",
        right: "left",
        bottom: "top",
        left: "right",
      }[computedPlacement.split("-")[0]];

      Object.assign(arrowElement.style, {
        left: arrowX != null ? `${arrowX}px` : "",
        top: arrowY != null ? `${arrowY}px` : "",
        right: "",
        bottom: "",
        [staticSide]: "-4px",
      });
    });
  });
}

function createNode(content) {
  if (typeof content === "string") {
    return document.createTextNode(content);
  }

  if (content instanceof HTMLElement) {
    return content;
  }

  const div = document.createElement("div");
  div.innerHTML = content;
  return div;
}

function createTooltip(el, content, placement) {
  const tooltip = document.createElement("div");
  tooltip.className = "tooltip";
  const tooltipText = createNode(content);
  tooltip.id = `tooltip-${Math.random().toString(36).substr(2, 9)}`;
  el.dataset.tooltipId = tooltip.id;
  tooltip.appendChild(tooltipText);

  document.body.appendChild(tooltip);

  const arrowElement = document.createElement("div");
  arrowElement.className = "arrow";
  tooltip.appendChild(arrowElement);

  tooltip.style.display = "block";

  nextTick(() => {
    updatePosition(el, tooltip, placement, arrowElement);
  });

  el.autoUpdateCleanup = autoUpdate(el, tooltip, () => {
    updatePosition(el, tooltip, placement, arrowElement);
  });
}

const vTooltip = {
  mounted(el, binding) {
    const { content, placement, delay = { show: 0, hide: 0 }, trigger = "hover", enabled = true, id = null } = binding.value;

    if (id) {
      el = document.getElementById(`${id.replace("#", "")}`);
    }

    const showTooltip = () => {
      if (enabled) {
        createTooltip(el, content, placement);
      }
    };

    const hideTooltip = () => {
      const tooltip = document.getElementById(el.dataset.tooltipId);
      if (tooltip) {
        tooltip.remove();
        el.dataset.tooltipId = "";
      }

      if (el.autoUpdateCleanup) {
        el.autoUpdateCleanup();
        el.autoUpdateCleanup = null;
      }
    };

    const removeTooltip = () => {
      clearTimeout(el.showTimeout);
      clearTimeout(el.hideTimeout);
      hideTooltip();
    };

    if (trigger === "hover") {
      el.addEventListener("mouseenter", () => {
        if (enabled) {
          el.showTimeout = setTimeout(showTooltip, delay.show);
        }
      });

      el.addEventListener("mouseleave", removeTooltip);

      el.addEventListener("mousedown", removeTooltip);

      el.addEventListener("dragstart", removeTooltip);

      el.addEventListener("dragend", removeTooltip);

      el.addEventListener("focus", removeTooltip);

      el.addEventListener("blur", removeTooltip);

      el.addEventListener("touchstart", () => {
        if (enabled) {
          el.showTimeout = setTimeout(showTooltip, delay.show);
        }
      });

      el.addEventListener("touchend", removeTooltip);

      el.addEventListener("touchcancel", removeTooltip);

      el.addEventListener("touchmove", removeTooltip);
    } else if (trigger === "click") {
      el.addEventListener("click", () => {
        if (enabled) {
          if (document.getElementById(el.dataset.tooltipId)) {
            hideTooltip();
          } else {
            showTooltip();
          }
        }
      });
    } else if (trigger === "focus") {
      el.addEventListener("focus", () => {
        if (enabled) {
          el.showTimeout = setTimeout(showTooltip, delay.show);
        }
      });

      el.addEventListener("blur", removeTooltip);
    }
  },
  updated(el, binding) {
    const { placement, delay = { show: 0, hide: 0 }, enabled = true, id = null } = binding.value;
    const tooltip = document.getElementById(el.dataset.tooltipId);

    if (enabled && tooltip) {
      if (id) {
        el = document.getElementById(`#${id.replace("#", "")}`);
      }

      clearTimeout(el.hideTimeout);
      el.showTimeout = setTimeout(() => {
        const arrowElement = tooltip.querySelector(".arrow");

        nextTick(() => {
          el.dataset.placement = placement;
          tooltip.style.display = "block";
          updatePosition(el, tooltip, placement, arrowElement);
        });

        if (!el.autoUpdateCleanup) {
          el.autoUpdateCleanup = autoUpdate(
            el,
            tooltip,
            () => {
              updatePosition(el, tooltip, placement, arrowElement);
            },
            {
              elementResize: true,
              elementScroll: true,
              elementMutation: true,
              animationFrame: true,
            },
          );
        }
      }, delay.show);
    } else if (!enabled && tooltip) {
      tooltip.style.display = "none";
    }
  },
  unmounted(el) {
    const tooltip = document.getElementById(el.dataset.tooltipId);
    if (tooltip) {
      tooltip.remove();
    }

    if (el.autoUpdateCleanup) {
      el.autoUpdateCleanup();
    }

    clearTimeout(el.showTimeout);
    clearTimeout(el.hideTimeout);
  },
};

export default vTooltip;
