//
// Globally declared components
//

// import { markRaw } from "vue";
import { ClientTable, ServerTable } from "v-tables-3";
import VueSecureHTML from "vue-html-secure";
import VueScrollactive from "vue-scrollactive";
import Aura from "@primeuix/themes/aura";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import BushelBtn from "@/components/common/BushelBtn.vue";
import BushelCard from "@/components/common/cards/BushelCard.vue";
import TabCard from "@/components/common/cards/TabCard.vue";
import NotAuthorizedBanner from "@/components/common/NotAuthorizedBanner.vue";
import Loading from "@/components/common/loaders/Default.vue";
import FullScreenLoader from "@/components/common/loaders/FullScreenLoader.vue";
import NoItems from "@/components/common/noItems/NoItems.vue";
import BushelTextInput from "@/components/common/forms/BushelTextInput.vue";
import BushelSelect from "@/components/common/forms/BushelSelect.vue";
import BushelTextArea from "@/components/common/forms/BushelTextArea.vue";
import DeleteConfirmationModal from "@/components/common/DeleteConfirmationModal.vue";
import DocumentList from "@/components/documents/DocumentList.vue";
import Tags from "@/components/common/Tags.vue";
import Tag from "@/components/common/Tag.vue";
import SupportArticleDrawer from "@/components/support/SupportArticleDrawer.vue";
import BushelModal from "@/components/common/modals/BushelModal.vue";
import PageDescriptionCard from "@/components/common/cards/PageDescriptionCard.vue";
import BushelAlert from "@/components/common/BushelAlert.vue";
import BushelDateInput from "@/components/common/forms/BushelDateInput.vue";
import BModal from "@/components/base/BModal.vue";
import BTooltip from "@/components/base/BTooltip.vue";
import BButtonGroup from "@/components/base/BButtonGroup.vue";
import BDropdown from "@/components/base/BDropdown.vue";
import BDropdownItem from "@/components/base/BDropdownItem.vue";
import vTooltip from "@/directives/vTooltip";

const defaultTableOpt = {
  sortIcon: {
    base: "fa float-none ml-1",
    up: "fa-chevron-up",
    down: "fa-chevron-down",
    is: "fa-sort",
  },
  skin: "table",
  filterByColumn: true,
  perPage: 10,
  perPageValues: [],
  texts: {
    filterBy: "",
  },
};

// exports with app for registering components
export default (app) => {
  app.use(VueSecureHTML);
  app.use(PrimeVue, {
    // Default theme configuration
    theme: {
      preset: Aura,
      options: {
        prefix: "p",
        darkModeSelector: "class",
        cssLayer: false,
      },
    },
  });

  app.use(ClientTable, defaultTableOpt, "bootstrap4");
  app.use(ServerTable, defaultTableOpt, "bootstrap4");

  app.use(VueScrollactive);
  app.use(ToastService);

  app.directive("tooltip", vTooltip);

  app.component("BTooltip", BTooltip);
  app.component("BModal", BModal);
  // app.component("BPopover", BPopover);
  // app.component("BCollapse", BCollapse);
  app.component("BButtonGroup", BButtonGroup);
  app.component("BDropdown", BDropdown);
  app.component("BDropdownItem", BDropdownItem);
  app.component("NotAuthorizedBanner", NotAuthorizedBanner);
  app.component("BushelBtn", BushelBtn);
  app.component("BushelAlert", BushelAlert);
  app.component("BushelCard", BushelCard);
  app.component("TabCard", TabCard);
  app.component("BushelTextInput", BushelTextInput);
  app.component("BushelDateInput", BushelDateInput);
  app.component("BushelSelect", BushelSelect);
  app.component("BushelTextArea", BushelTextArea);
  app.component("Loading", Loading);
  app.component("DeleteConfirmationModal", DeleteConfirmationModal);
  app.component("DocumentList", DocumentList);
  app.component("FullScreenLoader", FullScreenLoader);
  app.component("Tags", Tags);
  app.component("SupportArticleDrawer", SupportArticleDrawer);
  app.component("PageDescriptionCard", PageDescriptionCard);
  app.component("Tag", Tag);
  app.component("NoItems", NoItems);
  app.component("BushelModal", BushelModal);
};

/**
 * require(./languages)
 * require(./filters)
 */
