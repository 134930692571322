<template>
  <div class="bushel-form">
    <label class="" :class="[{ focussed: inputFocused }, labelClass]">{{ labelActive }} </label>
    <slot name="afterLabelSlot" />

    <i v-if="tooltipMessage" :id="tooltipId" class="fas fa-question text-info ml-2" :class="tooltipClass" :style="tooltipStyle" />
    <BTooltip v-if="tooltipMessage" :placement="tooltipPlacement" :content="tooltipMessage" :target="tooltipId" />
    <DatePicker
      :id="inputId"
      :value="localDate"
      input-class="mx-input"
      :type="type"
      :value-type="valueType"
      :format="computedFormat"
      :use12h="use12Hour"
      :clearable="allowClearDate && loading != true"
      :class="{ error: error, 'w-100': true }"
      :disabled="disabled"
      :placeholder="placeholder"
      :style="inputStyle"
      :hour-step="hourStep"
      :minute-step="minuteStep"
      :second-step="secondStep"
      :second-options="secondOptions"
      :hour-options="hourOptions"
      :minute-options="minuteOptions"
      :disabled-date="handleDisabledDate"
      @update:value="onInput"
      @change="(value) => $emit('change', value)"
      @focus="setOpen"
      @blur="setClosed"
      @open="setOpen"
      @close="setClosed"
      @clear="(value) => clearClicked(value)"
    />
    <i v-if="loading" class="fas fa-circle-notch fa-spin text-light" style="position: absolute; right: 47px; top: 35px" />
    <div v-if="noBottomPadding" class="" />
    <div v-else-if="thin" class="thin-padding" />
    <div v-else-if="error">
      <b-form-invalid-feedback id="inputLiveFeedback" class="message hidden-overflow-vis d-block mt-0">
        {{ errorText }}
      </b-form-invalid-feedback>
    </div>
    <div v-else class="regular-padding" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import DatePicker from "vue-datepicker-next";
import BFormInvalidFeedback from "@/components/base/BFormInvalidFeedback.vue";
import "vue-datepicker-next/index.css";

export default {
  components: {
    DatePicker,
    BFormInvalidFeedback,
  },
  props: {
    dontLocalize: { type: Boolean, default: false },
    tooltipId: { type: String },
    tooltipMessage: { type: String },
    tooltipClass: { type: String },
    tooltipStyle: { type: Object },
    tooltipPlacement: { type: String, default: "bottom" },
    labelActive: { type: String },
    labelClass: { type: String },
    type: { type: String, default: "date" },
    valueType: { type: String, default: "format" },
    format: { type: String },
    disabledStartDate: { type: String, default: "" },
    disabledEndDate: { type: String, default: "" },
    disabledStartDateInclusive: { type: Boolean, default: true },
    disabledEndDateInclusive: { type: Boolean, default: true },
    use12Hour: { type: Boolean, default: true },
    value: { type: null },
    modelValue: { type: null },
    hourStep: { type: Number, default: 1 },
    minuteStep: { type: Number, default: 15 },
    secondStep: { type: Number, default: 1 },
    hourOptions: { type: Array },
    minuteOptions: { type: Array },
    secondOptions: { type: Array, default: () => [] },
    inputStyle: { type: [String, Object, Array], default: "" },
    inputId: { type: String, default: null },
    thin: { type: Boolean },
    error: { type: Boolean, default: false },
    errorText: { type: String },
    disabled: { type: Boolean, default: false },
    noBottomPadding: { type: Boolean, default: false },
    allowClearDate: { type: Boolean, default: false },
    placeholder: { type: String },
    loading: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "input", "change", "clearButtonClicked"],
  data() {
    return {
      inputFocused: false,
      localDate: this.configurePropDate(),
    };
  },
  computed: {
    labelClasses() {
      let classes = this.labelClass;
      if (this.inputFocused) {
        classes += "foccussed";
      }
      return classes;
    },
    computedFormat() {
      if (this.format) {
        return this.format; // passed in custom prop
      }
      if (this.type == "date") {
        return "YYYY-MM-DD";
      }
      if (this.type == "datetime") {
        return "MM-DD-YYYY h:mm A";
      }
      if (this.type == "time") {
        return "hh:mm A";
      }
      return null;
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localDate = this.configurePropDate(newVal);
      }
    },
  },
  methods: {
    configurePropDate() {
      const localValue = this.value || this.modelValue;
      if (localValue === null) {
        return null;
      }
      if (this.dontLocalize) {
        const value = new Date(localValue);
        const offset = value.getTimezoneOffset();
        return new Date(value.getTime() + offset * 60 * 1000);
      }
      return localValue;
    },
    clearClicked(value) {
      this.$emit("clearButtonClicked", value);
    },
    onInput(value) {
      this.localDate = value;
      if (!value) {
        this.$emit("input", null);
        this.$emit("update:modelValue", null);
        return;
      }
      if (this.dontLocalize) {
        const offset = new Date().getTimezoneOffset();
        const utcTime = new Date(value.getTime() - offset * 60 * 1000);
        this.$emit("update:modelValue", utcTime);
        this.$emit("input", utcTime);
      } else {
        this.$emit("update:modelValue", value);
        this.$emit("input", value);
      }
    },
    handleDisabledDate(date) {
      let disabled = false;
      const dateInParsed = dayjs(date).format();
      const disabledStartDate = this.disabledStartDate ? dayjs(this.disabledStartDate).format() : false;
      const disabledEndDate = this.disabledEndDate ? dayjs(this.disabledEndDate).format() : false;
      if (disabledStartDate) {
        if (this.disabledStartDateInclusive) {
          disabled = dayjs(disabledStartDate).isAfter(dateInParsed);
        } else {
          disabled = dayjs(disabledStartDate).add(1, "d").isAfter(dateInParsed);
        }
      }
      if (disabledEndDate) {
        if (this.disabledEndDateInclusive) {
          disabled = dayjs(dateInParsed).subtract(1, "d").isAfter(disabledEndDate);
        } else {
          disabled = dayjs(dateInParsed).isAfter(disabledEndDate);
        }
      }
      return disabled;
    },
    setOpen() {
      this.inputFocused = true;
    },
    setClosed() {
      this.inputFocused = false;
    },
  },
};
</script>
<style scoped>
:deep(.xmx-datepicker) {
  width: 100%;
}
/*.error*/
:deep(.xmx-datepicker.error input) {
  border-color: #ff5454 !important;
}
:deep(.xmx-datepicker.error .xmx-icon-calendar, .xmx-datepicker.error .xmx-icon-calendar svg, .xmx-datepicker.error .xmx-icon-calendar svg path) {
  fill: #ff5454 !important;
}
</style>
