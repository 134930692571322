<template>
  <span
    class="badge"
    :style="{ backgroundColor: tag.background_color, color: tag.color }"
  >
    {{ tag.name }}
  </span>
</template>

<script>
export default {
  props: {
    tag: { type: Object, required: true },
  },
};
</script>
