<template>
  <div>
    <div :class="`drawer-container ${true ? 'drawer-backdrop' : ''}`">
      <div :class="`drawer ${true ? 'drawer-show' : ''} p-0 border-left shadow-sm`">
        <div class="px-3 py-2 row m-0 bg-light border-bottom">
          <BushelBtn class="btn-sm" variant="success" @click="$emit('hide')"> <i class="fas fa-times" /> Close </BushelBtn>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Drawer",
  components: {},
  props: {
    showDrawer: { type: Boolean, default: false },
  },
  emits: ["hide"],
  data() {
    return {};
  },
};
</script>
<style scoped>
.drawer {
  max-width: 70%;
  height: 100%;
  overflow: auto;
  background: #fff;
  position: fixed;
  top: 0;
  right: -70%;
  z-index: 9999;
  transition: right 1s;
}
.drawer-container {
  transition: background 1s ease-in-out;
}
.drawer-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 999;
}
.drawer-show {
  right: 0 !important;
}
</style>
