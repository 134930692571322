<template>
  <div class="rounded text-grey-400 p-3 bg-header">
    <slot />
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      //
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
