<template>
  <div>
    <Drawer
      v-if="show"
      :show-drawer="show"
      @hide="closeDrawer"
    >
      <SupportArticle :article-slug="slug" />
    </Drawer>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import SupportArticle from "./SupportArticle.vue";
import Drawer from "@/components/common/Drawer.vue";

export default {
  components: {
    SupportArticle,
    Drawer,
  },
  props: {
    slug: { type: String },
    show: { type: Boolean },
  },
  data() {
    return {
      showDrawer: false,
      industry: APP_INDUSTRY,  
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions("ui", ["handleSupportArticleDrawer"]),
    closeDrawer() {
      this.handleSupportArticleDrawer();
    },
  },
};
</script>
