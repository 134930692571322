<template>
  <span>
    <span v-for="tag in tags" :key="tag.name + '_' + tag.id" class="mb-2 mr-2">
      <Tag :tag="tag" />
    </span>
  </span>
</template>

<script>
export default {
  props: {
    tags: { type: Array, required: true },
  },
};
</script>
